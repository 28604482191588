<template>
  <div>
    <v-row justify="center" align="center" v-if="passwordIncorrect">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-alert
          border="top"
          colored-border
          color="red"
          elevation="2"
          class="my-3"
        >
          Email or password is not correct
        </v-alert>
      </v-col>
    </v-row>

    <v-col :cols="12" style="margin: 0; padding: 0">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" md="7" lg="6">
          <v-card class="mx-auto entercodebox" flat color="transparent">
            <v-card-text>
              <v-text-field
                label="Enter Email"
                outlined
                v-model="userEmail"
                class="entercodeboxinput hp--btn"
              ></v-text-field>
              <v-text-field
                label="Enter Password"
                outlined
                v-model="userPassword"
                class="entercodeboxinput hp--btn"
                @keydown.enter="submitUserForm"
              ></v-text-field>

              <v-btn
                @click="submitUserForm"
                depressed
                large
                color="primary"
                block
                class="hp--btn"
                >Submit</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12">
          <v-row justify="center" align="center" style="margin: 0 50px">
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicHeart.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicApple.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicSteth.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicSpine.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicMind.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicWalk.png"
                style="max-height: 100px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import * as UserAccess from "./../classes/userAccess";
export default {
  data() {
    return {
      userEmail: null,
      userPassword: null,
      passwordIncorrect: false,
      campaign: {},
    };
  },
  methods: {
    submitUserForm() {
      UserAccess.getUserAccessByEmail(this.userEmail).then((user) => {
        if (user.length > 0) {
          this.passwordIncorrect = false;
          this.$emit("validateUserLogin");
        } else {
          this.passwordIncorrect = true;
        }
      });
    },
  },
};
</script>