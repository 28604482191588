/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserAccess = /* GraphQL */ `
  query GetUserAccess($id: ID!) {
    getUserAccess(id: $id) {
      id
      name
      location
      department
      email
      createdAt
      updatedAt
      campaignManager {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUserAccesss = /* GraphQL */ `
  query ListUserAccesss(
    $filter: ModelUserAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAccesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        location
        department
        email
        createdAt
        updatedAt
        campaignManager {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userAccessByEmail = /* GraphQL */ `
  query UserAccessByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAccessByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        location
        department
        email
        createdAt
        updatedAt
        campaignManager {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVideoManager = /* GraphQL */ `
  query GetVideoManager($id: ID!) {
    getVideoManager(id: $id) {
      id
      title
      introduction
      pdf
      pdfFileName
      codes
      outro
      isDeleted
      createdAt
      updatedAt
      campaignManager {
        items {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVideoManagers = /* GraphQL */ `
  query ListVideoManagers(
    $filter: ModelVideoManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        introduction
        pdf
        pdfFileName
        codes
        outro
        isDeleted
        createdAt
        updatedAt
        campaignManager {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampaignManager = /* GraphQL */ `
  query GetCampaignManager($id: ID!) {
    getCampaignManager(id: $id) {
      id
      company
      accessCode
      accessPassword
      expiryDate
      showLocationDepartment
      active
      isDeleted
      userAccess {
        items {
          id
          name
          location
          department
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videoManager {
        id
        title
        introduction
        pdf
        pdfFileName
        codes
        outro
        isDeleted
        createdAt
        updatedAt
        campaignManager {
          nextToken
        }
      }
    }
  }
`;
export const listCampaignManagers = /* GraphQL */ `
  query ListCampaignManagers(
    $filter: ModelCampaignManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const campaignByAccessCode = /* GraphQL */ `
  query CampaignByAccessCode(
    $accessCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByAccessCode(
      accessCode: $accessCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVimeoVideo = /* GraphQL */ `
  query GetVimeoVideo($id: ID!) {
    getVimeoVideo(id: $id) {
      id
      vimeoId
      description
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listVimeoVideos = /* GraphQL */ `
  query ListVimeoVideos(
    $filter: ModelVimeoVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVimeoVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vimeoId
        description
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
