<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  async mounted() {
    await this.$store.dispatch("authAction");
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    layout() {
      return(this.$route.meta.layout);
    }
  }
};
</script>
