<template>
  <div id="app">
    <v-container>
      <v-row justify="center" align="center">
        <div style="max-width: 500px; margin-top: 10px">
          <a href="/">
            <img
              src="/assets/img/logo-hp.png"
              style="max-width: 100%; width: 100%"
            />
          </a>
        </div>
      </v-row>

      <CampaignLogin @validateCampaign="onValidateCampaign" v-if="showCampaignLogin" />

      <!-- <UserAccessLogin @validateUserLogin="onValidateUserLogin" v-if="showUserAccessLogin" /> -->

      <VideoPage v-if="showVideoPage" />

      <!-- <Footer /> -->
    </v-container>
  </div>
</template>

<script>
import CampaignLogin from "../components/CampaignLogin";
import UserAccessLogin from "../components/UserAccessLogin";
import VideoPage from "../components/VideoPage";

export default {
  name: "Home",
  components: {
    CampaignLogin,
    UserAccessLogin,
    VideoPage,
  },
  data() {
    return {
      showCampaignLogin: true,
      showUserAccessLogin: false,
      showVideoPage: false,
    }
  },
  methods: {
    onValidateCampaign() {
      this.showCampaignLogin = false;
      this.showVideoPage = true;
    },
    onValidateUserLogin() {
      this.showUserAccessLogin = false;
      this.showVideoPage = true;
    }
  }
};
</script>
