/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVideoManager = /* GraphQL */ `
  mutation CreateVideoManager(
    $input: CreateVideoManagerInput!
    $condition: ModelVideoManagerConditionInput
  ) {
    createVideoManager(input: $input, condition: $condition) {
      id
      title
      introduction
      pdf
      pdfFileName
      codes
      outro
      isDeleted
      createdAt
      updatedAt
      campaignManager {
        items {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVideoManager = /* GraphQL */ `
  mutation UpdateVideoManager(
    $input: UpdateVideoManagerInput!
    $condition: ModelVideoManagerConditionInput
  ) {
    updateVideoManager(input: $input, condition: $condition) {
      id
      title
      introduction
      pdf
      pdfFileName
      codes
      outro
      isDeleted
      createdAt
      updatedAt
      campaignManager {
        items {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVideoManager = /* GraphQL */ `
  mutation DeleteVideoManager(
    $input: DeleteVideoManagerInput!
    $condition: ModelVideoManagerConditionInput
  ) {
    deleteVideoManager(input: $input, condition: $condition) {
      id
      title
      introduction
      pdf
      pdfFileName
      codes
      outro
      isDeleted
      createdAt
      updatedAt
      campaignManager {
        items {
          id
          company
          accessCode
          accessPassword
          expiryDate
          showLocationDepartment
          active
          isDeleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCampaignManager = /* GraphQL */ `
  mutation CreateCampaignManager(
    $input: CreateCampaignManagerInput!
    $condition: ModelCampaignManagerConditionInput
  ) {
    createCampaignManager(input: $input, condition: $condition) {
      id
      company
      accessCode
      accessPassword
      expiryDate
      showLocationDepartment
      active
      isDeleted
      userAccess {
        items {
          id
          name
          location
          department
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videoManager {
        id
        title
        introduction
        pdf
        pdfFileName
        codes
        outro
        isDeleted
        createdAt
        updatedAt
        campaignManager {
          nextToken
        }
      }
    }
  }
`;
export const updateCampaignManager = /* GraphQL */ `
  mutation UpdateCampaignManager(
    $input: UpdateCampaignManagerInput!
    $condition: ModelCampaignManagerConditionInput
  ) {
    updateCampaignManager(input: $input, condition: $condition) {
      id
      company
      accessCode
      accessPassword
      expiryDate
      showLocationDepartment
      active
      isDeleted
      userAccess {
        items {
          id
          name
          location
          department
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videoManager {
        id
        title
        introduction
        pdf
        pdfFileName
        codes
        outro
        isDeleted
        createdAt
        updatedAt
        campaignManager {
          nextToken
        }
      }
    }
  }
`;
export const deleteCampaignManager = /* GraphQL */ `
  mutation DeleteCampaignManager(
    $input: DeleteCampaignManagerInput!
    $condition: ModelCampaignManagerConditionInput
  ) {
    deleteCampaignManager(input: $input, condition: $condition) {
      id
      company
      accessCode
      accessPassword
      expiryDate
      showLocationDepartment
      active
      isDeleted
      userAccess {
        items {
          id
          name
          location
          department
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      videoManager {
        id
        title
        introduction
        pdf
        pdfFileName
        codes
        outro
        isDeleted
        createdAt
        updatedAt
        campaignManager {
          nextToken
        }
      }
    }
  }
`;
export const updateUserAccess = /* GraphQL */ `
  mutation UpdateUserAccess(
    $input: UpdateUserAccessInput!
    $condition: ModelUserAccessConditionInput
  ) {
    updateUserAccess(input: $input, condition: $condition) {
      id
      name
      location
      department
      email
      createdAt
      updatedAt
      campaignManager {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUserAccess = /* GraphQL */ `
  mutation DeleteUserAccess(
    $input: DeleteUserAccessInput!
    $condition: ModelUserAccessConditionInput
  ) {
    deleteUserAccess(input: $input, condition: $condition) {
      id
      name
      location
      department
      email
      createdAt
      updatedAt
      campaignManager {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createVimeoVideo = /* GraphQL */ `
  mutation CreateVimeoVideo(
    $input: CreateVimeoVideoInput!
    $condition: ModelVimeoVideoConditionInput
  ) {
    createVimeoVideo(input: $input, condition: $condition) {
      id
      vimeoId
      description
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateVimeoVideo = /* GraphQL */ `
  mutation UpdateVimeoVideo(
    $input: UpdateVimeoVideoInput!
    $condition: ModelVimeoVideoConditionInput
  ) {
    updateVimeoVideo(input: $input, condition: $condition) {
      id
      vimeoId
      description
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteVimeoVideo = /* GraphQL */ `
  mutation DeleteVimeoVideo(
    $input: DeleteVimeoVideoInput!
    $condition: ModelVimeoVideoConditionInput
  ) {
    deleteVimeoVideo(input: $input, condition: $condition) {
      id
      vimeoId
      description
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createUserAccess = /* GraphQL */ `
  mutation CreateUserAccess(
    $input: CreateUserAccessInput!
    $condition: ModelUserAccessConditionInput
  ) {
    createUserAccess(input: $input, condition: $condition) {
      id
      name
      location
      department
      email
      createdAt
      updatedAt
      campaignManager {
        id
        company
        accessCode
        accessPassword
        expiryDate
        showLocationDepartment
        active
        isDeleted
        userAccess {
          nextToken
        }
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          isDeleted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
