import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { Auth } from "aws-amplify";

Vue.use(VueRouter);

const routes = [
  {
    path: "/video-manager",
    name: "VideoManagerPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/VideoManagerPage.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/campaign-manager",
    name: "CampaignManagerPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CampaignManagerPage.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/user-access",
    name: "UserAccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserAccess.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/add-video",
    name: "UserAccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddVideo.vue"),
    meta: { requiresAuth: true, layout: "DefaultLayout" },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Login.vue"),
    meta: { requiresAuth: true, layout: "FullPageLayout" }
  },
  {
    path: "/:campaigncode",
    name: "Home",
    component: Home,
    meta: { layout: "DefaultLayout" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user;
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (data && data.signInUserSession) {
          user = data;
          if (typeof to.meta.role !== "undefined") {
            // Roles
            let userRoles =
              user.signInUserSession.accessToken.payload["cognito:groups"];
            let role = to.meta.role;
            let authorised = false;
            for (var i = 0; i < userRoles.length; i++) {
              if (role === userRoles[i]) {
                authorised = true;
              }
            }
            if (!authorised) {
              next({ path: "/login" });
            }
          }
        }
        next();
      })
      .catch(() => {
        next({ path: "/login" });
      });
  }
  next();
});

export default router;
