<template>
  <div>
    <v-app-bar color="#3a3a3a" dark flat app v-if="user">
      <v-toolbar-title>Healthy Performance OnDemand</v-toolbar-title>
      <v-spacer style="z-index: 1"></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn color="#c0c700" text to="/">Home</v-btn> -->
        <v-btn color="#c0c700" text to="/video-manager">Video Page Manager</v-btn>
        <v-btn color="#c0c700" text to="/campaign-manager">Campaign Manager</v-btn>
        <v-btn color="#c0c700" text to="/user-access">User access</v-btn>
        <v-btn color="#c0c700" text to="/add-video" style="margin-right: 55px;">Add video</v-btn>
        <v-speed-dial
          style="right: 20px; top: 10px;"
          fixed
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab small>
              <v-icon v-if="fab">mdi-close</v-icon>
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-btn>
          </template>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="green"
                href="https://hpapps.healthyperformance.co.uk/profile"
                target="_blank"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Update profile</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="orange"
                href="https://hpapps.healthyperformance.co.uk/changepassword"
                target="_blank"
                v-on="on"
              >
                <v-icon>mdi-lock-open-outline</v-icon>
              </v-btn>
            </template>
            <span>Change password</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                dark
                small
                color="indigo"
                href="https://hpapps.healthyperformance.co.uk/users"
                target="_blank"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add user</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn fab dark small color="red" @click="onSignOut()" v-on="on">
                <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-speed-dial>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    async onSignOut() {
      await this.$store.dispatch("logout");
      this.$router.push({ path: "login" });
    }
  },
  data: () => ({
    direction: "bottom",
    hover: false,
    transition: "slide-y-reverse-transition",
    fab: false,
    isValidOperations: false,
    isConsultantRole: false,
    isAdminRole: false
  }),
  created() {
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>