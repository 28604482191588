import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import store from "./store";
import vueVimeoPlayer from "vue-vimeo-player";
import DefaultLayout from "./layouts/DefaultLayout";
import FullPageLayout from "./layouts/FullPageLayout";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "eu-west-2:f49bffc9-84d2-4f7f-8a37-e2f0a750487c",

    // REQUIRED - Amazon Cognito Region
    region: "eu-west-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-west-2_2HVDIm7Ho",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "6rq15a8q7ofmqd9m8b0a943f6h",

    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
  Storage: {
    AWSS3: {
      bucket: "hp-internal-document-storage",
      region: "eu-west-2",
    },
  },
});
Amplify.configure(awsconfig);

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('FullPageLayout', FullPageLayout);
Vue.use(vueVimeoPlayer)
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Let's style this up
require("./assets/style.css");
