import * as mutations from "./../graphql/mutations";
import * as queries from "./../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
export function createCampaignManager({ company, accessCode, password, showLocationDepartment, expiryDate, videoManager }) {
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.createCampaignManager, {
        input: {
          company: company,
          accessCode: accessCode,
          accessPassword: password,
          showLocationDepartment, showLocationDepartment,
          expiryDate: expiryDate,
          campaignManagerVideoManagerId: videoManager,
          isDeleted: 0,
          active: true,
        },
      })
    ).then((result) => {
      let campaign = result.data.createCampaignManager;
      return resolve({
        id: campaign.id,
        company: campaign.company,
        accessCode: campaign.accessCode,
        accessPassword: campaign.accessPassword,
        showLocationDepartment: campaign.showLocationDepartment,
        expiryDate: campaign.expiryDate,
        active: campaign.active,
      })
    });
  });
  return campaignManagerOutput;
}

export function updateCampaignManager({ id, company, accessCode, password, showLocationDepartment, expiryDate, active, videoManager }) {
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateCampaignManager, {
        input: {
          id: id,
          company: company,
          accessCode: accessCode,
          accessPassword: password,
          showLocationDepartment: showLocationDepartment,
          expiryDate: expiryDate,
          campaignManagerVideoManagerId: videoManager,
          active: active,
        },
      })
    ).then((result) => {
      let campaign = result.data.updateCampaignManager;
      return resolve({
        id: campaign.id,
        company: campaign.company,
        accessCode: campaign.accessCode,
        accessPassword: campaign.password,
        showLocationDepartment: campaign.showLocationDepartment,
        expiryDate: campaign.expiryDate,
        active: campaign.active,
      })
    });
  });
  return campaignManagerOutput;
};

export function deleteCampaignManager(id) {
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateCampaignManager, {
        input: {
          id: id,
          isDeleted: 1,
        },
      })
    ).then((result) => {
      return resolve(result.data.updateCampaignManager);
    });
  });
  return campaignManagerOutput;
}

export function getCampaignManagerById(id) {
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(queries.getCampaignManager, {
        id: id,
      })
    ).then((result) => {
      return resolve(result.data.getCampaignManager);
    });
  });
  return campaignManagerOutput;
}

export function confirmCampaignAccessCode(code) {
  const campaignByAccessCode = /* GraphQL */ `
  query CampaignByAccessCode(
    $accessCode: String
  ) {
    campaignByAccessCode(
      accessCode: $accessCode
    ) {
      items {
        id
        company
        accessCode
        accessPassword
        showLocationDepartment
        expiryDate
        active
        createdAt
        updatedAt
        videoManager {
          id
          title
          introduction
          pdf
          pdfFileName
          codes
          outro
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql({
      query: campaignByAccessCode,
      variables: {
        accessCode: code,
      },
      authMode: "API_KEY",
    }).then((result) => {
      return resolve(result.data.campaignByAccessCode.items);
    });
  });
  return campaignManagerOutput;
}

export function getCampaignManagerByAccessCode(code) {
  const campaignByAccessCode = /* GraphQL */ `
    query CampaignByAccessCode(
      $accessCode: String
    ) {
      campaignByAccessCode(
        accessCode: $accessCode
      ) {
        items {
          id
          company
          accessCode
          accessPassword
          expiryDate
          active
          createdAt
          updatedAt
          videoManager {
            id
            title
            introduction
            pdf
            pdfFileName
            codes
            outro
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  `;
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql({
      query: campaignByAccessCode,
      variables: {
        accessCode: code,
      },
      authMode: "API_KEY",
    }).then((result) => {
      return resolve(result.data.campaignByAccessCode.items);
    });
  });
  return campaignManagerOutput;
}

export async function getAllCampaigns() {
  var nextRecord = (nextToken) => {
    return new Promise(async (resolve) => {
      API.graphql(
        graphqlOperation(queries.listCampaignManagers, {
          filter: { isDeleted: { eq: 0 } },
          nextToken: nextToken,
        })
      ).then((result) => {
        return resolve(result.data.listCampaignManagers);
      });
    });
  }

  const result = await nextRecord(null);
  let nextToken = result.nextToken;
  let campaignManagerOutput = result.items;
  while (nextToken !== null) {
    let nextCampaignManager = await nextRecord(nextToken);
    campaignManagerOutput.push(...nextCampaignManager.items);
    nextToken = nextCampaignManager.nextToken;
  }
  return campaignManagerOutput;
}