<template>
  <div>
    <v-row justify="center" align="center" v-if="passwordIncorrect">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-alert
          border="top"
          colored-border
          color="red"
          elevation="2"
          class="my-3"
        >
          Access password is not correct
        </v-alert>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-if="!campaignExists">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-alert
          border="top"
          colored-border
          color="red"
          elevation="2"
          class="my-3"
        >
          Campaign code is not valid
        </v-alert>
      </v-col>
    </v-row>

    <v-row justify="center" align="center" v-if="campaignExpired">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-alert
          border="top"
          colored-border
          color="red"
          elevation="2"
          class="my-3"
        >
          Campaign has expired
        </v-alert>
      </v-col>
    </v-row>

    <v-col :cols="12" style="margin: 0; padding: 0" v-else>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" md="7" lg="6">
          <v-card class="mx-auto entercodebox" flat color="transparent">
            <v-card-text>
              <v-form ref="accessVideoAppForm">
                <v-text-field
                  label="Access password"
                  outlined
                  v-model="campaignForm.campaignPassword"
                  :rules="[rules.required]"
                  class="entercodeboxinput hp--btn"
                  @keydown.enter="submitCampaignPassword"
                ></v-text-field>

                <v-text-field
                  label="Your name"
                  outlined
                  v-model="campaignForm.name"
                  :rules="[rules.required]"
                  class="entercodeboxinput hp--btn"
                  @keydown.enter="submitCampaignPassword"
                ></v-text-field>

                <v-text-field
                  label="Your Email"
                  outlined
                  v-model="campaignForm.email"
                  :rules="[rules.required, rules.email]"
                  class="entercodeboxinput hp--btn"
                  @keydown.enter="submitCampaignPassword"
                ></v-text-field>

                <v-text-field
                  label="Location"
                  outlined
                  v-model="campaignForm.location"
                  class="entercodeboxinput hp--btn"
                  @keydown.enter="submitCampaignPassword"
                  v-if="showLocationDepartment"
                ></v-text-field>

                <v-text-field
                  label="Department"
                  outlined
                  v-model="campaignForm.department"
                  class="entercodeboxinput hp--btn"
                  @keydown.enter="submitCampaignPassword"
                  v-if="showLocationDepartment"
                ></v-text-field>

                <v-btn
                  @click="submitCampaignPassword"
                  depressed
                  large
                  color="primary"
                  block
                  class="hp--btn"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="12">
          <v-row justify="center" align="center" style="margin: 0 50px">
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicHeart.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicApple.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicSteth.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicSpine.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicMind.png"
                style="max-height: 100px"
              />
            </v-col>
            <v-col :cols="2" style="text-align: center">
              <img
                src="/assets/img/fgraphicWalk.png"
                style="max-height: 100px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import * as CampaignManager from "./../classes/campaignManager";
import * as UserAccess from "./../classes/userAccess";
import moment from "moment";
export default {
  data() {
    return {
      campaignForm: {},
      campaignPassword: null,
      campaignExists: false,
      passwordIncorrect: false,
      campaignExpired: false,
      campaign: {},
      showLocationDepartment: false,
      rules: {
        required: (v) => !!v || "This field is required.",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
    };
  },
  mounted() {
    if (this.$route.params.campaigncode) {
      CampaignManager.confirmCampaignAccessCode(
        this.$route.params.campaigncode.toLowerCase()
      ).then((campaign) => {
        this.showLocationDepartment = campaign[0].showLocationDepartment;
        if (campaign.length > 0) {
          this.campaignExists = true;
          let expiry = moment(campaign[0].expiryDate).diff(moment(), "hours");
          if (expiry > 0) {
            this.campaign = campaign[0];
          } else {
            this.campaignExpired = true;
          }
        }
      });
    }
  },
  methods: {
    submitCampaignPassword() {
      if (this.$refs.accessVideoAppForm.validate()) {
        this.campaignForm.campaignId = this.campaign.id;
        if (
          this.campaign.accessPassword !== this.campaignForm.campaignPassword
        ) {
          this.passwordIncorrect = true;
        } else {
          UserAccess.createUserAccess(this.campaignForm).then((result) => {
            this.$emit("validateCampaign");
            this.passwordIncorrect = false;
            // this.userAccess.push(result);
            // this.createUserAccessDialog = false;
            // this.showSuccessMessage(`New video page ${result.name} added`);
          });
          this.$emit("validateCampaign");
          this.passwordIncorrect = false;
        }
      }
    },
  },
};
</script>