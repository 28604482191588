import * as mutations from "./../graphql/mutations";
import * as queries from "./../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
export function createUserAccess({ name, location, department, email, campaignId }) {
  var UserAccessOutput = new Promise(function (resolve, reject) {
    const createUserAccess = /* GraphQL */ `
      mutation CreateUserAccess(
        $input: CreateUserAccessInput!
        $condition: ModelUserAccessConditionInput
      ) {
        createUserAccess(input: $input, condition: $condition) {
          id
          name
          location
          department
          email
          createdAt
          updatedAt
          campaignManager {
            id
            company
            accessCode
            accessPassword
            expiryDate
            active
            createdAt
            updatedAt
            videoManager {
              id
              title
              introduction
              pdf
              pdfFileName
              codes
              outro
              createdAt
              updatedAt
            }
          }
        }
      }
    `;

    API.graphql({
      query: createUserAccess,
      variables: {
        input: {
          name: name,
          location: location,
          department: department,
          email: email,
          userAccessCampaignManagerId: campaignId,
        },
      },
      authMode: "API_KEY",
    }).then((result) => {
      return resolve(result.data.createUserAccess);
    });
  });
  return UserAccessOutput;
}

export function updateUserAccess({ id, name, location, department, email }) {
  var UserAccessOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.updateUserAccess, {
        input: {
          id: id,
          name: name,
          location: location,
          department: department,
          email: email,
        },
      })
    ).then((result) => {
      let userAccess = result.data.updateUserAccess;
      return resolve({
        id: userAccess.id,
        name: userAccess.name,
        location: userAccess.location,
        department: userAccess.department,
        email: userAccess.email,
      })
    });
  });
  return UserAccessOutput;
};

export function deleteUserAccess(id) {
  var UserAccessOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(mutations.deleteUserAccess, {
        input: {
          id: id,
        },
      })
    ).then((result) => {
      return resolve(result.data.deleteUserAccess);
    });
  });
  return UserAccessOutput;
}

export function getUserAccessById(id) {
  var UserAccessOutput = new Promise(function (resolve, reject) {
    API.graphql(
      graphqlOperation(queries.getUserAccess, {
        id: id,
      })
    ).then((result) => {
      return resolve(result.data.getUserAccess);
    });
  });
  return UserAccessOutput;
}

export function getUserAccessByEmail(email) {
  var campaignManagerOutput = new Promise(function (resolve, reject) {
    API.graphql({
      query: queries.userAccessByEmail,
      variables: {
        email: email,
      },
      authMode: "API_KEY",
    }).then((result) => {
      return resolve(result.data.userAccessByEmail.items);
    });
  });
  return campaignManagerOutput;
}

export async function getAllUsersAccess() {
  var nextRecord = (nextToken) => {
    return new Promise(async (resolve) => {
      API.graphql(
        graphqlOperation(queries.listUserAccesss, {
          nextToken: nextToken,
        })
      ).then((result) => {
        return resolve(result.data.listUserAccesss);
      });
    });
  }

  const result = await nextRecord(null);
  let nextToken = result.nextToken;
  let UserAccessOutput = result.items;
  while (nextToken !== null) {
    let nextUserAccess = await nextRecord(nextToken);
    UserAccessOutput.push(...nextUserAccess.items);
    nextToken = nextUserAccess.nextToken;
  }
  return UserAccessOutput;
}