<template>
  <div style="min-width: 1000px">
    <v-row>
      <div class="text-h1 mb-6 text-center white--text">
        {{ video.title }}
      </div>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined align="center" class="py-5 px-1">
          {{ video.introduction }}
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="video.pdf">
      <v-col>
        <v-btn
          color="indigo"
          class="ma-2 white--text"
          @click="downloadPDF"
          block
        >
          Download PDF workbook
          <v-icon right dark> mdi-cloud-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-3">
      <template v-for="(code, index) in codes">
        <v-col :key="index">
          <v-card outlined tile align="center">
            <v-card-title class="justify-center pb-0">
              {{ code.text }}
            </v-card-title>
            <vimeo-player
              ref="player"
              :video-id="code.value"
              player-width="450"
            ></vimeo-player>
          </v-card>
        </v-col>
        <v-responsive
          v-if="index + 1 === 2"
          :key="`width-${index + 1}`"
          width="100%"
        ></v-responsive>
      </template>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined align="center" class="py-5">
          {{ video.outro }}
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import * as CampaignManager from "./../classes/campaignManager";
export default {
  data() {
    return {
      video: {},
      codes: [],
    };
  },
  mounted() {
    CampaignManager.getCampaignManagerByAccessCode(
      this.$route.params.campaigncode.toLowerCase()
    ).then((campaign) => {
      if (campaign.length > 0) {
        this.video = campaign[0].videoManager;
        const codes = JSON.parse(this.video.codes);
        console.log(codes);
        // const vimeoIds = codes.map((vimeo) => vimeo.value);
        this.codes = codes;
      }
    });
  },
  methods: {
    async downloadPDF() {
      Storage.get(this.video.pdf, {
        download: true,
      }).then((data) => {
        var blob = new Blob([data["Body"]], { type: data["ContentType"] });
        this.forceFileDownload(blob, this.video.pdfFileName);
      });
    },
    forceFileDownload(response, fileName) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>