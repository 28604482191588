var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('v-app-bar',{attrs:{"color":"#3a3a3a","dark":"","flat":"","app":""}},[_c('v-toolbar-title',[_vm._v("Healthy Performance OnDemand")]),_c('v-spacer',{staticStyle:{"z-index":"1"}}),_c('v-toolbar-items',[_c('v-btn',{attrs:{"color":"#c0c700","text":"","to":"/video-manager"}},[_vm._v("Video Page Manager")]),_c('v-btn',{attrs:{"color":"#c0c700","text":"","to":"/campaign-manager"}},[_vm._v("Campaign Manager")]),_c('v-btn',{attrs:{"color":"#c0c700","text":"","to":"/user-access"}},[_vm._v("User access")]),_c('v-btn',{staticStyle:{"margin-right":"55px"},attrs:{"color":"#c0c700","text":"","to":"/add-video"}},[_vm._v("Add video")]),_c('v-speed-dial',{staticStyle:{"right":"20px","top":"10px"},attrs:{"fixed":"","direction":_vm.direction,"open-on-hover":_vm.hover,"transition":_vm.transition},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","fab":"","small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-account-circle")])],1)]},proxy:true}],null,false,2633837866),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green","href":"https://hpapps.healthyperformance.co.uk/profile","target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3836788868)},[_c('span',[_vm._v("Update profile")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"orange","href":"https://hpapps.healthyperformance.co.uk/changepassword","target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-lock-open-outline")])],1)]}}],null,false,2080463775)},[_c('span',[_vm._v("Change password")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"indigo","href":"https://hpapps.healthyperformance.co.uk/users","target":"_blank"}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,683583923)},[_c('span',[_vm._v("Add user")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.onSignOut()}}},on),[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}],null,false,1471618455)},[_c('span',[_vm._v("Logout")])])],1)],1)],1):_vm._e(),_c('v-main',[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }